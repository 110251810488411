export const photos = [
  {
    original: `https://ik.imagekit.io/ass77/drive-download-20230511T095234Z-${`002/107637-363.jpg?tr=w-1000,h-666`}`,
    thumbnail: `https://ik.imagekit.io/ass77/drive-download-20230511T095234Z-${`002/107637-363.jpg?tr=w-300,h-200`}`,
  },
  {
    original: `https://ik.imagekit.io/ass77/drive-download-20230511T095234Z-${`002/107637-361.jpg?tr=w-1000,h-666`}`,
    thumbnail: `https://ik.imagekit.io/ass77/drive-download-20230511T095234Z-${`002/107637-361.jpg?tr=w-300,h-200`}`,
  },
  {
    original: `https://ik.imagekit.io/ass77/drive-download-20230511T095234Z-${`001/107637-142.jpg?tr=w-1000,h-666`}`,
    thumbnail: `https://ik.imagekit.io/ass77/drive-download-20230511T095234Z-${`001/107637-142.jpg?tr=w-300,h-200`}`,
  },
  {
    original: `https://ik.imagekit.io/ass77/drive-download-20230511T095234Z-${`002/107637-392.jpg?tr=w-1000,h-666`}`,
    thumbnail: `https://ik.imagekit.io/ass77/drive-download-20230511T095234Z-${`002/107637-392.jpg?tr=w-300,h-200`}`,
  },
  {
    original: `https://ik.imagekit.io/ass77/drive-download-20230511T095234Z-${`002/107637-286.jpg?tr=w-1000,h-666`}`,
    thumbnail: `https://ik.imagekit.io/ass77/drive-download-20230511T095234Z-${`002/107637-286.jpg?tr=w-300,h-200`}`,
  },
];
